var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"clients",attrs:{"full-width":""}},[_c('v-row',{staticClass:"mr-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"title ml-6 pt-4"},[_vm._v("CLIENTS")]),_c('div',{staticClass:"line ml-6"})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"autocomplete ml-6 mt-4",attrs:{"solo":"","dense":"","flat":"","placeholder":"Rechercher un client par nom, marché... etc"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[_c('template',{staticClass:"searchField",slot:"append"},[_c('img',{staticClass:"search-logo",attrs:{"src":require("@/assets/search.png")}})])],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"ml-6",attrs:{"headers":_vm.headers,"items":_vm.clients,"items-per-page":parseInt(_vm.displayItem),"options":_vm.options,"server-items-length":_vm.totalItems,"page":_vm.page,"hide-default-header":"","hide-default-footer":"","no-data-text":"Aucune donnée disponible"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id_client",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'client',
              params: {
                id_client: item.id_client,
              },
            }}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v("voir les détails")])])]}},{key:"item.logo",fn:function(ref){
            var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"60","max-width":"25","src":_vm.getImgUrl(item.logo)}})]}},{key:"header",fn:function(ref){
            var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item){return _c('th',{key:item.value,staticClass:"column minWidth",class:[item.sortable ? 'sortable' : ''],on:{"click":function($event){item.sortable ? _vm.handler(item.value, _vm.options) : ''}}},[_vm._v(" "+_vm._s(item ? item.text : "")+" "),(
                    item == undefined ||
                      (_vm.options.sortBy == item.value &&
                        _vm.options.sortDesc == 'ASC')
                  )?_c('i',{staticClass:"fas fa-caret-up"}):(item.text != '')?_c('i',{staticClass:"fas fa-caret-down"}):_vm._e()])}),0)])]}}],null,true)}),_c('v-pagination',{staticClass:"mt-6",attrs:{"length":_vm.nbPage,"circle":"","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }